import { ProductModel } from '@/models/Product.model';
import React from 'react';
import SaleBadge from './badge/SaleBadge';
import AtbBadge from './badge/AtbBadge';
import OutOfStockBadge from './badge/OutOfStockBadge';
import FreeItemBadge from './badge/FreeItemBadge';
import FreeInstallationBadge from './badge/FreeInstallationBadge';
import NewBadge from './badge/NewBadge';
import The1PointXBadge from './badge/The1PointXBadge';
import ClearanceBadge from './badge/ClearanceBadge';
import BuyMoreBadge from './badge/BuyMoreBadge';
import HugThai from './badge/HugThai';

interface Props {
  product: ProductModel;
  isShort?: boolean;
  isHideShowStock?: boolean;
}

export default function BadgeLayout({
  product,
  isShort,
  isHideShowStock,
}: Props) {
  return (
    <>
      {!isShort && (
        <div
          className={`absolute ${
            product.skuStatus && product.skuStatus === 'D'
              ? '-top-1 lg:-top-2'
              : 'top-0'
          } right-0 flex flex-col items-center`}
        >
          {product.skuStatus && product.skuStatus === 'D' ? (
            <ClearanceBadge discountPercent={product.discountPercent} />
          ) : (
            <SaleBadge discountPercent={product.discountPercent} />
          )}

          {product.theOnePromotion !== undefined &&
            product.theOnePromotion > 1 && (
              <div className={product.discountPercent ? `mt-0.5` : ''}>
                <The1PointXBadge point={product.theOnePromotion} />
              </div>
            )}
          {(product.freegiftMessage !== undefined ||
            product.freegiftBundle !== undefined) && (
            <div className={product.discountPercent ? `mt-0.5` : ''}>
              <FreeItemBadge sku={product.sku} />
            </div>
          )}
        </div>
      )}
      <div className="absolute bottom-5 left-0">
        <AtbBadge
          list={[...(product.atbBadges ?? [])].map((e) => e.base64 ?? '')}
        />
      </div>
      <div className="absolute bottom-0 right-0 w-1/2">
        {/* <EventBadge path="/images/logo/badge/88.png" /> */}
      </div>
      {(product.stockAvail ?? 0) <= 0 &&
        !isHideShowStock &&
        !product.isLineButton && (
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <OutOfStockBadge
              isShort={isShort}
              sku={product.sku}
              skuStatus={product.skuStatus}
            />
          </div>
        )}
      {product.freeInstallBadge !== undefined &&
        product.freeInstallBadge !== 0 && (
          <div className="absolute bottom-0 left-0">
            <FreeInstallationBadge />
          </div>
        )}
      <div className="absolute z-10 bottom-0 left-0">
        <div className="flex items-center h-4">
          {product.tagNew !== undefined && product.tagNew === 1 && (
            <NewBadge product={product} />
          )}
          {product.isBuyMore === true && <BuyMoreBadge product={product} />}
        </div>
      </div>
      {product.canBulky === true && <></>}
      {[
        product.skuStatus,
        product.theOnePromotion,
        product.freegiftMessage,
        product.freegiftBundle,
      ].filter(Boolean).length < 3 &&
        product.hugThai !== undefined &&
        product.hugThai !== 0 &&
        !isShort && (
          <div className="absolute -bottom-4 md:bottom-0 right-1 ">
            <HugThai />
          </div>
        )}
    </>
  );
}
