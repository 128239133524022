import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { ProductModel } from '@/models/Product.model';
import ProductImageBase64 from '../common/ProductImageBase64';
import ReviewProduct from './ReviewProduct';
import WishListProduct from './WishListProduct';
import CompareProduct from './CompareProduct';
import AddToCartButton from './AddToCartButton';
import { useSelector } from 'react-redux';
import AppLink from '../Layout/handler/AppLink';
import { useAppDispatch } from '@/store/store';
import { addProductToList } from '@/store/slices/productViewSendSlice';
import BadgeLayout from './BadgeLayout';
import SplashBadge from './badge/SplashBadge';
import Image from 'next/image';
import { openStockModal } from '@/store/slices/storeSlice';
import {
  AlgoliaTrackingEvent,
  algoliaTracking,
} from '@/services/client/algoliaService';
import { getGuestID } from '@/utils/algolia';
import { serviceAtbFlags } from '@/constants/defaultValue.constant';
import BulkySameDayBadge from './badge/BulkySameDayBadge';
import ProductCounter from '../common/ProductCounter';
import { customerInfoSelector } from '@/store/slices/authenSlice';
import NotifyButton from './AddToCartButton/NotifyButton';
import PriceMsgSlide from './PriceMsgSlide/PriceMsgSlide';
import OutOfStockButton from './AddToCartButton/OutOfStockButton';

export enum CardType {
  // eslint-disable-next-line no-unused-vars
  normal = 1,
  // eslint-disable-next-line no-unused-vars
  slide = 2,
  // eslint-disable-next-line no-unused-vars
  spu = 3,
}

interface Props {
  position?: number;
  listName?: string;
  product: ProductModel;
  type?: CardType;
  isShort?: boolean;
  customAddToCartButton?: React.ReactNode;
  // eslint-disable-next-line no-unused-vars
  overrideClickedProduct?: (product: ProductModel) => void;
  queryId?: string;
  isShowAddToCart?: boolean;
  isFlashSale?: boolean;
  isShortWithQty?: boolean;
}

export default function ProductCard({
  position,
  listName,
  product,
  type,
  isShort,
  customAddToCartButton,
  overrideClickedProduct,
  queryId,
  isShowAddToCart,
  isFlashSale,
  isShortWithQty,
}: Props) {
  type = type ?? CardType.normal;
  if (type === CardType.spu && (product.spu === 0 || product.skuCount === 1)) {
    type = CardType.normal;
  }
  const { t, i18n } = useTranslation('productcard');
  const customerInfo = useSelector(customerInfoSelector);
  const customerId = customerInfo?.custId;
  const productRef = useRef<HTMLDivElement>(null);
  const checkStockAvail =
    product.stockAvail !== undefined &&
    product.stockAvail <= 0 &&
    product.isLineButton !== true;

  const dispatch = useAppDispatch();
  const [quantity, setQuantity] = useState<number>(1);

  useEffect(() => {
    const productSend = productRef.current;
    if (
      !productSend ||
      !(window as any).dataLayer ||
      customerInfo === undefined
    )
      return;

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        dispatch(addProductToList({ ...product, position, listName }));
        observer.disconnect();
      }
    });

    observer.observe(productSend);

    return () => {
      if (productSend && observer) {
        observer.unobserve(productSend);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId]);

  const handleAddToCartCallback = () => {
    if (queryId !== undefined) {
      algoliaTracking({
        eventName: AlgoliaTrackingEvent.convertedObjectIDsAfterSearch,
        userToken: getGuestID(),
        authenticatedUserToken: customerId ?? undefined,
        queryID: queryId,
        objectIDs: [product.sku],
        lang: i18n.language,
      });
    }
  };

  const handleProductClick = () => {
    if (overrideClickedProduct === undefined) {
      if (queryId !== undefined) {
        algoliaTracking({
          eventName: AlgoliaTrackingEvent.clickedObjectIDsAfterSearch,
          userToken: getGuestID(),
          authenticatedUserToken: customerId ?? undefined,
          queryID: queryId,
          objectIDs: [product.sku],
          positions: [product.aglPosition ?? 0],
          lang: i18n.language,
        });
      }
      if ((window as any).dataLayer) {
        (window as any).dataLayer.push({
          event: 'productClick',
          userId: customerId,
          ecommerce: {
            list: listName, // Optional list property.
            products: [
              {
                name: product.name,
                id: product.sku,
                price: product.price ? product.price.replace(/,/g, '') : '0',
                brand: product.brand ?? '',
                position: position,
                category: product.class ?? '',
                quantity: 1,
                list: listName ?? '',
              },
            ],
          },
        });
      }
    }
  };

  const handleCheckStock = (e: any) => {
    e.preventDefault();
    dispatch(
      openStockModal({
        sku: product.sku,
        type: 1,
        qty: 1,
        isCheckStockStore: true,
        // isHomeFlag: priceset?.hb !== undefined && priceset.hb.trim() === 'H',
        // isLongtail: longtailAtbFlags.includes(priceset?.abtflag ?? ''),
        // hasStock: (priceset?.stockavail ?? 0) > 0,
        isService: serviceAtbFlags.includes(product?.atbFlag ?? ''),
      }),
    );
  };
  return (
    <div
      className={`pt-1 md:pt-2 ${
        type === CardType.slide ? 'pb-1' : 'pb-2'
      } bg-white hover:shadow-md cursor-pointer rounded ${
        isShort ? 'border' : ''
      }`}
      itemID={queryId}
      itemProp={product.aglPosition?.toString()}
    >
      <div ref={productRef}>
        <AppLink
          href={
            overrideClickedProduct !== undefined
              ? undefined
              : `/product/${product.slugname}`
          }
          onClick={
            overrideClickedProduct !== undefined
              ? () => {
                  overrideClickedProduct(product);
                }
              : handleProductClick
          }
        >
          <div className="relative mx-1 md:mx-2">
            <div id={`product-image-${product.sku}`} className="p-xs-0 p-lg-5">
              <div
                className={`${
                  isShort ? 'max-w-[72px]' : 'max-w-[198px]'
                } max-h-[${isShort ? '72' : '198'}px] mx-auto`}
              >
                <ProductImageBase64
                  pimImage={product.pimImage}
                  base64={product.base64}
                  alt={product.name}
                  className="aspect-square"
                />
              </div>
            </div>
            <BadgeLayout product={product} isShort={isShort} />
          </div>
        </AppLink>
        <div className="mt-1">
          {!isShort && (
            <AppLink
              href={
                overrideClickedProduct !== undefined
                  ? undefined
                  : `/brand/${product.brand}`
              }
              onClick={
                overrideClickedProduct !== undefined
                  ? () => {
                      overrideClickedProduct(product);
                    }
                  : undefined
              }
            >
              <div className="leading-3 h-4 overflow-hidden mb-0.5 px-1 md:px-2">
                <span className="font-semibold text-lg leading-5 hover:underline">
                  {product.brand}
                </span>
              </div>
            </AppLink>
          )}
          <AppLink
            href={
              overrideClickedProduct !== undefined
                ? undefined
                : `/product/${product.slugname}`
            }
            onClick={
              overrideClickedProduct !== undefined
                ? () => {
                    overrideClickedProduct(product);
                  }
                : handleProductClick
            }
          >
            <div className="px-1 md:px-2">
              <div className="leading-3 h-9 md:h-8 overflow-hidden mb-0.5">
                <span className="text-base leading-3 md:leading-4 hover:underline line-clamp-3 lg:line-clamp-2">
                  {product.name}
                </span>
              </div>
              {!isShort && (
                <>
                  <div className="flex items-center justify-between">
                    {type !== CardType.spu ? (
                      <div className="leading-3 h-3 text-sm text-grayDark flex-1">
                        <span className="hidden lg:inline">
                          {t('common:sku')}:{' '}
                        </span>
                        <span className="skushort">
                          {t('common:skushort')}:{' '}
                        </span>
                        {product.sku}
                      </div>
                    ) : (
                      <div className="block sm:hidden h-3"></div>
                    )}
                    <div className="flex flex-nowrap items-center justify-around ml-auto px-1 lg:px-0 h-3">
                      <WishListProduct product={product} />
                      {type !== CardType.spu && (
                        <div className="ms-1">
                          <CompareProduct
                            sku={product.sku}
                            pimImage={product.pimImage}
                            urlimg={product.pimImage}
                            slugname={product.slugname}
                            isShort={isShort}
                            isNotText={true}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="h-[0.9rem]">
                    {type !== CardType.spu ? (
                      <ReviewProduct
                        totalReview={product.totalReview}
                        avgReview={product.avgReview}
                        size={0.6}
                        alwayShowSpace={true}
                      />
                    ) : (
                      <div className="leading-3 text-primary text-sm">
                        {t('common:start')}
                      </div>
                    )}
                  </div>
                </>
              )}
              {product.spu === 0 &&
              !product.badgeBuyDisc &&
              ((product?.priceMsgTierPlp?.[0]?.minValue ?? 0) > 1 ||
                (product?.priceMsgBonusPlp?.[0]?.minValue ?? 0) > 1) ? (
                <PriceMsgSlide product={product} />
              ) : (
                <div className="flex items-center">
                  <div className="w-full">
                    <div className="flex justify-between items-center">
                      <div className="text-redPrice font-bold text-lg leading-3">
                        ฿
                      </div>
                      {type !== CardType.spu && (
                        <div className="text-grayDark text-sm leading-3 line-through">
                          {product.discount
                            ? `฿${product.discount}`
                            : undefined}
                        </div>
                      )}
                    </div>
                    <div
                      className={`text-center text-lg sm:text-xl md:text-${
                        isShort ? 'xl' : '2xl'
                      } leading-5 ${
                        type !== CardType.spu
                          ? 'h-5 md:h-6'
                          : 'h-9 md:h-[2.5rem] flex flex-col'
                      } sm:leading-6 font-price text-redPrice`}
                    >
                      {type !== CardType.spu ? (
                        product.price
                      ) : (
                        <div className="my-auto">{`${product.minPrice} - ${product.maxPrice}`}</div>
                      )}
                    </div>
                    {!isShort && !isFlashSale && product.spu === 0 && (
                      <div
                        className={`block sm:hidden ${
                          type !== CardType.spu ? 'h-5' : 'h-1.5'
                        } mt-0.5"`}
                      >
                        {product.badgeBuyDisc !== undefined && (
                          <SplashBadge badgeBuyDisc={product.badgeBuyDisc} />
                        )}
                      </div>
                    )}
                    {type !== CardType.spu && (
                      <>
                        <div
                          className={`text-xs leading-4 line-clamp-1 text-right`}
                        >
                          {product.remark ? (
                            <span className="text-grayDark">{`${product.remark}`}</span>
                          ) : (
                            `/${product.unitName}`
                          )}
                        </div>

                        {isShort ? (
                          <div
                            className={`flex justify-between items-center h-5 mb-1 sm:mb-0${
                              isShortWithQty ? ' space-x-1' : ''
                            }`}
                          >
                            <div className="flex w-full">
                              {isShortWithQty ? (
                                <div className="mb-[1px]">
                                  <ProductCounter
                                    height={1.32}
                                    minQty={product.minQty}
                                    packSize={product.packSize}
                                    currentValue={quantity}
                                    onChange={(value: number) => {
                                      setQuantity(value);
                                    }}
                                  />
                                </div>
                              ) : (
                                <>
                                  <WishListProduct
                                    product={product}
                                    size={0.7}
                                  />
                                  <div className="ms-1">
                                    <CompareProduct
                                      sku={product.sku}
                                      pimImage={product.pimImage}
                                      urlimg={product.pimImage}
                                      slugname={product.slugname}
                                      size={0.7}
                                      isShort={isShort}
                                    />
                                  </div>
                                </>
                              )}
                            </div>
                            <div className={isShortWithQty ? 'w-full' : ''}>
                              <AddToCartButton
                                sku={
                                  product.isDateBetweenChristmas
                                    ? undefined
                                    : product.sku
                                }
                                isLineButton={product.isLineButton}
                                atbFlag={product.atbFlag}
                                isOutOfStock={
                                  product.stockAvail !== undefined &&
                                  product.stockAvail <= 0
                                }
                                height={1.3}
                                addToCartCallback={handleAddToCartCallback}
                                isShort={isShort}
                                isShortWithQty={isShortWithQty}
                                qty={quantity}
                                isChristmas={product.isBaseChristmasSku}
                                addToCartText={
                                  product.isDateBetweenChristmas
                                    ? `${t('productcard:startselling')}`
                                    : undefined
                                }
                              />
                            </div>
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </>
                    )}
                  </div>
                  {product.badgeBuyDisc !== undefined &&
                    !isShort &&
                    !isFlashSale &&
                    product.spu === 0 && (
                      <div className="hidden sm:block w-36 py-0.5 ps-0.5">
                        <SplashBadge badgeBuyDisc={product.badgeBuyDisc} />
                      </div>
                    )}
                </div>
              )}
              {type === CardType.slide && isShowAddToCart === true && (
                <div className="block sm:flex items-end w-full">
                  <div className="ps-0.5 h-7 flex justify-end w-full">
                    {checkStockAvail ? (
                      <div className="flex justify-between w-full">
                        <div
                          className="select-none cursor-pointer flex items-center space-x-0.5 hover:underline text-primary text-sm"
                          onClick={handleCheckStock}
                        >
                          <div>
                            <Image
                              src="/images/svg/header/location_pin_red.svg"
                              alt="location pin red"
                              width={24}
                              height={24}
                            />
                          </div>
                          <div className="text-xs lg:text-sm xl:text-base">
                            {t('common:checkstock')}
                          </div>
                        </div>
                        <div>
                          {product.skuStatus && product.skuStatus === 'D' ? (
                            <OutOfStockButton />
                          ) : (
                            <NotifyButton sku={product.sku} isPlp={true} />
                          )}
                        </div>
                      </div>
                    ) : (
                      <div>
                        <AddToCartButton
                          sku={
                            product.isDateBetweenChristmas
                              ? undefined
                              : product.sku
                          }
                          isLineButton={product.isLineButton}
                          atbFlag={product.atbFlag}
                          isOutOfStock={
                            product.stockAvail !== undefined &&
                            product.stockAvail <= 0
                          }
                          addToCartCallback={handleAddToCartCallback}
                          isChristmas={product.isBaseChristmasSku}
                          addToCartText={
                            product.isDateBetweenChristmas
                              ? `${t('productcard:startselling')}`
                              : undefined
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
              {[CardType.normal, CardType.spu].includes(type) && (
                <div
                  className={`${
                    type !== CardType.spu ? 'flex' : 'flex h-[2.4rem] sm:h-auto'
                  } sm:flex items-end`}
                >
                  <BulkySameDayBadge canBulky={product.canBulky} />
                  <div
                    className={`${
                      checkStockAvail ? 'flex-grow' : 'ms-auto justify-end'
                    } ps-0.5 h-7 flex flex-col `}
                  >
                    {type !== CardType.spu ? (
                      customAddToCartButton && !checkStockAvail ? (
                        customAddToCartButton
                      ) : checkStockAvail ? (
                        <div className="grid grid-cols-2 justify-between">
                          <div
                            className="select-none cursor-pointer flex items-center space-x-0.5 hover:underline text-primary text-sm"
                            onClick={handleCheckStock}
                          >
                            <div>
                              <Image
                                src="/images/svg/header/location_pin_red.svg"
                                alt="location pin red"
                                width={24}
                                height={24}
                              />
                            </div>
                            <div className="text-xs lg:text-sm xl:text-base">
                              {t('common:checkstock')}
                            </div>
                          </div>
                          <div>
                            {product.skuStatus && product.skuStatus === 'D' ? (
                              <OutOfStockButton />
                            ) : (
                              <NotifyButton sku={product.sku} isPlp={true} />
                            )}
                          </div>
                        </div>
                      ) : (
                        <AddToCartButton
                          sku={
                            product.isDateBetweenChristmas
                              ? undefined
                              : product.sku
                          }
                          isLineButton={product.isLineButton}
                          atbFlag={product.atbFlag}
                          isOutOfStock={
                            product.stockAvail !== undefined &&
                            product.stockAvail <= 0
                          }
                          xsShort
                          addToCartCallback={handleAddToCartCallback}
                          isChristmas={product.isBaseChristmasSku}
                          addToCartText={
                            product.isDateBetweenChristmas
                              ? `${t('productcard:startselling')}`
                              : undefined
                          }
                        />
                      )
                    ) : (
                      <div className="text-sm cursor-pointer hover:underline line-clamp-1">{`${t(
                        'common:seeall',
                      )} ${product.skuCount ?? 1} ${
                        (product.skuCount ?? 1) > 1
                          ? t('productlist:items')
                          : t('productlist:items').replace('items', 'item')
                      }`}</div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </AppLink>
        </div>
      </div>
    </div>
  );
}
