import React from 'react';
import Image from 'next/image';
export default function HugThai() {
  return (
    <div>
      <div>
        <Image
          src="/images/badge/hugthai.svg"
          alt="gift"
          width={40}
          height={75}
          style={{ margin: 'auto' }}
          className="w-[36px] sm:w-[40px] h-[75px]"
        />
      </div>
    </div>
  );
}
